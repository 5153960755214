























































































































































@import '~@/assets/style/variables';

.about {
  background-color: $pink;
  background-image: url('/img/bgs/bg_japan_003.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 750px auto;

  &-bloc-title {
    position: relative;

    h4 {
      position: absolute;
      bottom: 97%;
      color: $white;

      margin: 0;
      padding: 0;
    }
  }

  &__description {
    font-size: $font-size-1;
    line-height: 1.5em;

    padding: 0 0 $gutter-md 0;
    margin: $gutter-md 0 $gutter-lg 0;

    max-width: 550px;

    border-bottom: 1px solid rgba(236,232,225,.4);
  }

  &__citation {
    background-color: $grey-100;
    color: $dark;

    padding: $gutter;
    margin: $gutter-md 0;

    @include card(1);

    max-width: 550px;
  }

  &__planning, &__social {
    padding: $gutter;
    margin: $gutter-md 0;
  }
}
