



















































@import '~@/assets/style/variables';

.demineur {
  background-color: $pink;
  padding: 16px;
}
