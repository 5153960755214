












































































































































@import '~@/assets/style/variables';

.partnerhips {
  background-color: $pink;

  h1 {
    @include text-shadow(3);
  }
}
