







































































































































































































































































@import '~@/assets/style/variables';

$dirt-size: 50px;

$revealed-color: #ae813e; //orange-brown 1
$revealed-color-odd: #b58f57; //orange-brown 2

$dirt-color: #8bc34a;
$dirt-color-odd: #4caf50;

$niveau-1: #3f51b5;
$niveau-2: #81c784;
$niveau-3: #f44336;
$niveau-4: #9c27b0;
$niveau-5: #ffc107;
$niveau-6: #4dd0e1;
$niveau-7: #607d8b;
$niveau-8: #000000;

.row {
  display: flex;
  flex:1;
  height: $dirt-size;

  .dirt {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: visible;
    align-items: center;
    width: $dirt-size;
    background-color: $dirt-color;

    span {
      display: block;
      width: 66%;
      height: 66%;
      font-weight: 600;
      color: white;
      font-size: 1.6rem;
      text-align: center;
      line-height: 2rem;

      .emoji {
        width: 100%;
        height: 100%;
      }

      &.niveau-1 {
        color: $niveau-1;
      }

      &.niveau-2 {
        color: $niveau-2;
      }

      &.niveau-3 {
        color: $niveau-3;
      }

      &.niveau-4 {
        color: $niveau-4;
      }

      &.niveau-5 {
        color: $niveau-5;
      }

      &.niveau-6 {
        color: $niveau-6;
      }

      &.niveau-7 {
        color: $niveau-7;
      }

      &.niveau-8 {
        color: $niveau-8;
      }
    }

    &:nth-child(2n) {
      background-color: $dirt-color-odd;
    }

    &.revealed {
      z-index: 1;
      background-color: $revealed-color;

      &:nth-child(2n) {
        background-color: $revealed-color-odd;
      }
    }

    &:hover {
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: rgba(250, 250, 250, .23);
      }
    }
  }

  &:nth-child(2n) {
    .dirt {
      background-color: $dirt-color-odd;

      &:nth-child(2n) {
        background-color: $dirt-color;
      }

      &.revealed {
        background-color: $revealed-color-odd;
        &:nth-child(2n) {
          background-color: $revealed-color;
        }
      }
    }
  }
}
