

























@import '~@/assets/style/variables';

#twitch-embed {
  margin-top: 16px;
  background: $dark;

  @media screen and (min-width: $screen-md + 1) {
    height: $screen-lg * 9/16;
  }

  @media screen and (max-width: $screen-md) {
    height: $screen-md * 9/16;
  }

  @media screen and (max-width: $screen-sm) {
    height: $screen-sm * 9/16;
  }

  @media screen and (max-width: $screen-xs) {
    height: $screen-xs * 9/16;
  }
}
