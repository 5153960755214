









































































































































@import '~@/assets/style/variables';

.footer {
  position: relative;
  background-color: $dark;
  color: $white;
  padding-bottom: $gutter-lg;
  text-align: center;

  section {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  nav {
    .title {
      text-align: center;
      text-decoration: underline;
      padding-bottom: $gutter;
    }

    margin-top: $gutter-lg;
    margin-right: $gutter;
    padding-bottom: $gutter-lg;
  }
}
