










































































@import '~@/assets/style/variables';

.tombola {
  background-color: $pink;
}
