
































































@import '~@/assets/style/variables';

#app {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  background-color: $dark;

  main {
    position: relative;
    padding-top: $gutter-lg;

    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .view {
    position: relative;
    flex: 1;
    overflow: auto;
  }
}
