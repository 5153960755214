

























































































































@import '~@/assets/style/variables';

.planning {
  background-attachment: fixed;
  background-image: url('~@/assets/background.jpg');
  background-size: cover;
  background-position: center center;

  section {
    position: relative;
    color: $white;

    h1 {
      @include text-shadow(3);
    }

    img.planning-img {
      max-width: 100%;
      @include card(2);
      border-radius: $gutter;
    }
  }
}

#background-video {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  iframe {
    position: absolute;
    top: -10%;
    bottom: 0;
    left: -10%;
    min-width: 120%;
    min-height: 120%;

    filter: blur(15px);
  }
}
