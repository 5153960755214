























































































@import '~@/assets/style/variables';

.home {
  background-color: $palette-pink;

  &__content {
    position: relative;
    padding: $gutter-lg 0;

    &:before {
      content: '';
      position: absolute;
      top: -48px;
      left: 0;
      width: 30%;
      border-bottom: 48px solid $palette-pink;
      border-right: 48px solid transparent;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;

      // border-left: .5px solid $white;
      padding: $gutter;
    }
  }
}
